import gql from 'graphql-tag';

export const TransferBalance = gql`
  mutation giftCardsMerge($input: GiftCardsMergeInput!) {
    giftCardsMerge(input: $input) {
      beginBalance
      currencyCode
      currentBalance
      destinationCardNumber
      fdCorrelationId
      sourceCardNumber
      transactionId
    }
  }
`;
