import React, { useContext, createContext } from 'react';

import useRollUpTheRim from 'hooks/use-rutr';

export const RollUpTheRimContext = createContext();
export const useLocationContext = () => useContext(RollUpTheRimContext);

export const RollUpTheRimProvider = ({ children }) => {
  const { loading, error, events, count, getRollUpTheRimTimeline } = useRollUpTheRim();

  return (
    <RollUpTheRimContext.Provider
      value={{
        count,
        error,
        events,
        getRollUpTheRimTimeline,
        loading,
      }}
    >
      {children}
    </RollUpTheRimContext.Provider>
  );
};

export default RollUpTheRimContext.Consumer;
