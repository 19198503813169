import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

const FALLBACK_SAMPLING_RATE = 100;

const useDatadogRum = () => {
  useEffect(() => {
    const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
    const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
    const gqlUrl = process.env.REACT_APP_GQL_URL ?? '';

    if (applicationId && clientToken) {
      datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        service: 'admin-app',
        env: process.env.REACT_APP_RBI_ENV,
        version: process.env.REACT_APP_COMMIT_REF,
        sessionSampleRate: FALLBACK_SAMPLING_RATE,
        trackUserInteractions: true,
        trackResources: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [gqlUrl],
      });
    }
  }, []);
};

export default useDatadogRum;
