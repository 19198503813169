import { HandlerType, datadogLogs } from '@datadog/browser-logs';

export function init(): void {
  let handler: HandlerType = HandlerType.http;

  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  if (clientToken) {
    datadogLogs.init({
      clientToken,
      forwardErrorsToLogs: true,
      env: process.env.REACT_APP_RBI_ENV,
      service: 'admin-app',
      version: process.env.REACT_APP_COMMIT_REF,
    });
  }

  datadogLogs.logger.setHandler(handler);
}
