import { navigate } from '@reach/router';

import {
  PREVIEW_URL,
  REDIRECTED_FROM_STAGING,
  SHOULD_LOGIN_WITH_OKTA,
  SHOULD_REDIRECT_TO_PREVIEW,
} from '../../components/okta-sign-in-button/constants';
import { getOktaRedirectUrl } from '../../containers/sign-in/okta';
import { routes } from '../../utils/routing';

export const setQueryParamsToLocalStorage = (queryString: string): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  const allowedKeys: string[] = [SHOULD_LOGIN_WITH_OKTA, PREVIEW_URL, REDIRECTED_FROM_STAGING];

  if (queryString) {
    const params: { [key: string]: string } = {};

    const startIndex = queryString.indexOf('?');
    if (startIndex !== -1) {
      const cleanedString = queryString.substring(startIndex + 1);
      const paramPairs = cleanedString.split('&');

      paramPairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        if (
          allowedKeys.includes(decodedKey) ||
          decodedKey.includes('CognitoIdentityServiceProvider')
        ) {
          params[decodedKey] = decodedValue;
          localStorage.setItem(decodedKey, decodedValue);
        }
      });
    }
  }

  return params;
};

export const useOktaSignInRedirect = (): any => {
  setQueryParamsToLocalStorage(window.location.href);
  const isRedirectedFromStaging: boolean = window.location.href.includes(
    `${REDIRECTED_FROM_STAGING}=true`,
  );
  if (isRedirectedFromStaging) {
    navigate(routes.default);
  }
  if (localStorage.getItem(SHOULD_LOGIN_WITH_OKTA) === 'true') {
    const oktaLoginUrl = getOktaRedirectUrl();
    localStorage.setItem(SHOULD_REDIRECT_TO_PREVIEW, 'true');
    navigate(oktaLoginUrl, { replace: true });
  }
};
