import { FC, PropsWithChildren } from 'react';

import { useAuthContext } from 'state/auth';

import { Permission } from '../../../services/graphql/src/lib/users/roles-and-permissions';

const RestrictedComponent: FC<
  PropsWithChildren<{
    restrictedComponent?: React.ReactElement;
    accessPermission?: Permission;
    overrideAndHide?: boolean;
  }>
> = ({ restrictedComponent, accessPermission, overrideAndHide, children }) => {
  const { permissions } = useAuthContext();

  if (overrideAndHide) {
    return null;
  }

  const isAccessNeeded = !!accessPermission;

  if (isAccessNeeded) {
    return permissions.includes(accessPermission) ? <>{children}</> : restrictedComponent ?? null;
  }

  return <>{children}</>;
};

export default RestrictedComponent;
