import { ComponentType, Suspense, lazy, LazyExoticComponent, FC } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { RouteComponentProps, Router } from '@reach/router';

import Layout from 'components/layout';
import Loader from 'components/loader';
import ReduceProviders from 'components/reduce-providers';
import brandConfigs from 'config/brand-resolution';
import useDatadogRum from 'hooks/rum/use-datadog-rum';
import { useAuthContext } from 'state/auth';
import { init as initDatadogLogs } from 'utils/datadog';

import { OktaSignInButton } from './components/okta-sign-in-button';
import { isPreview } from './components/utils';
import { useOktaSignInRedirect } from './hooks/use-okta-sign-in-redirect/use-okta-sign-in-redirect';
import providers from './providers';
import { routes } from './utils/routing';

import './index.css';

type LazyRoute = LazyExoticComponent<ComponentType<any>>;

const CtgLoyalty: LazyRoute = lazy(() => import('./containers/ctg-loyalty'));
const CTGLoyaltyTransaction: LazyRoute = lazy(() => import('./containers/ctg-loyalty-transaction'));
const Customer: LazyRoute = lazy(() => import('./containers/customer'));
const GiftCard: LazyRoute = lazy(() => import('./containers/giftcard'));
const Home: LazyRoute = lazy(() => import('./containers/home'));
const HockeyChallenges: LazyRoute = lazy(() => import('./containers/hockey-challenges'));
const LoyaltyDetails: LazyRoute = lazy(() => import('./components/ctg-loyalty-legacy/details'));
const LoyaltyHistory: LazyRoute = lazy(() => import('./components/ctg-loyalty-legacy/history'));
const LoyaltyLegacy: LazyRoute = lazy(() => import('./containers/loyalty-legacy'));
const LoyaltySearch: LazyRoute = lazy(() => import('./components/ctg-loyalty-legacy/search'));
const OffersLD: LazyRoute = lazy(() => import('./containers/offers-ld'));
const OffersTH: LazyRoute = lazy(() => import('./containers/offers-unified'));
const OffersV1: LazyRoute = lazy(() => import('./containers/offers-unified'));
const OffersV2: LazyRoute = lazy(() => import('./containers/offers-unified'));
const Order: LazyRoute = lazy(() => import('./containers/order'));
const Orders: LazyRoute = lazy(() => import('./containers/orders'));
const ReleaseNotes: LazyRoute = lazy(() => import('./containers/release-notes'));
const RollUpTheRim: LazyRoute = lazy(() => import('./components/rutr-timeline'));
const SignIn: LazyRoute = lazy(() => import('./containers/sign-in'));
const StoreDetails: LazyRoute = lazy(() => import('./containers/store'));
const SupportHistory: LazyRoute = lazy(() => import('./containers/support-history'));
const THLoyalty: LazyRoute = lazy(() => import('./containers/th-loyalty'));
const THLoyaltyDetails: LazyRoute = lazy(() => import('./components/loyalty-details'));
const THLoyaltyHistory: LazyRoute = lazy(() => import('./components/loyalty-history'));
const THLoyaltySearch: LazyRoute = lazy(() => import('./components/loyalty-search'));
const THLoyaltyTransaction: LazyRoute = lazy(() => import('./containers/th-loyalty-transaction'));
const FSTransaction: LazyRoute = lazy(() => import('./containers/fs-transactions'));

const NotFound: FC<RouteComponentProps> = () => <p>Sorry, nothing here</p>;

const ContentRouting = () => {
  const { ctgLoyaltyEnabled, thLoyaltyEnabled } = brandConfigs;
  const { isAuthenticated } = useAuthContext();
  useOktaSignInRedirect();
  useDatadogRum();
  initDatadogLogs();

  return (
    <Suspense fallback={<Loader />}>
      {isAuthenticated() ? (
        <Router primary={false}>
          <Home path={routes.default} />

          <Customer path={routes.customer} />
          <Customer path={`${routes.customer}/:customerId`} />

          <Orders path={routes.orders} />
          <Orders path={`${routes.customer}/:customerId${routes.orders}`} />
          <Order path={`${routes.customer}/:customerId${routes.orders}/:orderId`} />

          <GiftCard path={routes.giftCard} />
          <GiftCard path={`${routes.giftCard}/:isoCountryCode/:cardNumber`} />
          <GiftCard path={`${routes.customer}/:customerId${routes.giftCard}`} />

          <FSTransaction path={routes.fsTransactions} />
          <FSTransaction path={`${routes.customer}/:customerId${routes.fsTransactions}`} />

          {thLoyaltyEnabled && (
            <>
              <HockeyChallenges path={routes.hockeyChallenges} />
              <HockeyChallenges path={`${routes.customer}/:customerId${routes.hockeyChallenges}`} />
            </>
          )}

          {thLoyaltyEnabled && (
            <>
              <LoyaltyLegacy path={routes.thLoyaltyLegacy}>
                <THLoyaltySearch path={routes.default} />
                <THLoyaltyDetails path="/:customerId" />
                <THLoyaltyHistory path={`/:customerId${routes.history}`} />
                <RollUpTheRim path={`/:customerId${routes.rollUpTheRim}/:thLegacyCognitoId`} />
              </LoyaltyLegacy>
              <OffersTH path={routes.offersTH} key={routes.offersTH} />
              <OffersTH path={`${routes.customer}/:customerId${routes.offersTH}`} />
              <THLoyalty path={routes.thLoyalty} />
              <THLoyalty path={`${routes.customer}/:customerId${routes.thLoyalty}`} />
              <RollUpTheRim
                path={`${routes.customer}/:customerId${routes.thLoyalty}/:thLegacyCognitoId${routes.rollUpTheRim}`}
              />
              <THLoyaltyTransaction
                path={`${routes.customer}/:customerId${routes.thLoyaltyTransaction}/:transactionId`}
              />
            </>
          )}
          {ctgLoyaltyEnabled && (
            <>
              <OffersLD path={routes.offersLD} key={routes.offersLD} />
              <OffersLD path={`${routes.customer}/:customerId${routes.offersLD}`} />
              <OffersV1 path={routes.offersV1} key={routes.offersV1} />
              <OffersV1 path={`${routes.customer}/:customerId${routes.offersV1}`} />
              <OffersV2 path={routes.offersV2} key={routes.offersV2} />
              <OffersV2 path={`${routes.customer}/:customerId${routes.offersV2}`} />
              <LoyaltyLegacy path={routes.loyaltyLegacy}>
                <LoyaltySearch path={routes.default} />
                <LoyaltyDetails path="/:loyaltyId" />
                <LoyaltyHistory path={`/:loyaltyId${routes.history}`} />
              </LoyaltyLegacy>
              <CtgLoyalty path={routes.ctgLoyalty} />
              <CtgLoyalty path={`${routes.customer}/:customerId${routes.ctgLoyalty}`} />
              <CTGLoyaltyTransaction
                path={`${routes.customer}/:customerId${routes.ctgLoyaltyTransaction}/:transactionId`}
              />
            </>
          )}

          <StoreDetails path={routes.store} />
          <StoreDetails path={`${routes.store}/:storeId`} />

          <ReleaseNotes path={routes.releaseNotes} />
          <ReleaseNotes path={`${routes.releaseNotes}/:date`} />

          <SupportHistory path={routes.supportHistory} />
          <SupportHistory path={`${routes.customer}/:customerId${routes.supportHistory}`} />
          <NotFound default />
        </Router>
      ) : (
        <SignIn />
      )}
      {isPreview() && !isAuthenticated() && <OktaSignInButton />}
    </Suspense>
  );
};

const App = () => {
  return (
    <ReduceProviders providers={providers}>
      <CssBaseline />
      <Layout>
        <ContentRouting />
      </Layout>
    </ReduceProviders>
  );
};

export default App;
