import { useQuery } from '@apollo/react-hooks';

import { GetLaunchDarklyFlag } from 'remote/queries';
import { useAuthContext } from 'state/auth';

import { LaunchDarklyFlagResponse } from '../../../services/graphql/src/generated/graphql';

const useFeatureFlag = (flagName: string, defaultValue?: boolean) => {
  const { cognitoId } = useAuthContext();

  const { data, ...rest } = useQuery<{ LaunchDarklyFlag: LaunchDarklyFlagResponse }>(
    GetLaunchDarklyFlag,
    {
      variables: {
        customerId: cognitoId,
        flagName,
        defaultValue,
      },
    },
  );

  return {
    data: data?.LaunchDarklyFlag.response,
    ...rest,
  };
};

export default useFeatureFlag;
