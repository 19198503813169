import { useState, useEffect, Fragment, PropsWithChildren, FC } from 'react';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import clsx from 'clsx';

import DummyText from 'components/dummy-text';
import RestrictedComponent from 'components/restricted-component';

import { useStyles } from './styles';
import { SummaryProps, actionType, annotationType } from './types';

const Summary = ({
  actions,
  annotations,
  content,
  header,
  icon,
  isLoading,
  subheader,
  preventBlinking,
}: SummaryProps) => {
  const classes = useStyles();

  const [blink, setBlink] = useState(false);
  useEffect(() => {
    setBlink(true);
    const timer = setTimeout(() => {
      setBlink(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [subheader]);

  const renderAnnotations = (_annotations?: Array<annotationType>) => {
    return (
      <>
        {_annotations
          ? _annotations.map((annotation: annotationType, index: number) => (
              <div className={classes.chipsWrapper} key={index}>
                <Tooltip title={annotation?.tooltipText || ''}>
                  <Chip
                    label={isLoading ? <DummyText width={100} /> : annotation?.label}
                    avatar={
                      isLoading ? undefined : (
                        <Avatar className={classes.avatar}>
                          <Receipt
                            fontSize="small"
                            className={clsx(classes.icon, classes.avatarText)}
                          />
                        </Avatar>
                      )
                    }
                  />
                </Tooltip>
              </div>
            ))
          : null}
      </>
    );
  };
  const renderActions = (_actions?: Array<actionType>) => {
    return isLoading ? (
      <DummyText width={120} />
    ) : (
      <>
        {_actions
          ? _actions.map(({ accessPermission, ...action }: actionType, index: number) => {
              let Wrapper: FC<PropsWithChildren<{}>> = Fragment;

              if (typeof accessPermission !== 'undefined') {
                Wrapper = ({ children }) => (
                  <RestrictedComponent
                    accessPermission={accessPermission}
                    restrictedComponent={
                      <Tooltip
                        placement="top"
                        title="You do not have authorization to use this feature."
                      >
                        <div>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            disabled={true}
                            disableElevation
                            onClick={() => {}}
                          >
                            {action?.label}
                          </Button>
                        </div>
                      </Tooltip>
                    }
                  >
                    {children}
                  </RestrictedComponent>
                );
              }

              return (
                <Wrapper key={index}>
                  <Button
                    color="primary"
                    disabled={isLoading || action.disabled}
                    disableElevation
                    onClick={action.onClick}
                    size="small"
                    variant="contained"
                  >
                    {action?.label}
                  </Button>
                </Wrapper>
              );
            })
          : null}
      </>
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={header}
        subheader={
          isLoading ? (
            <DummyText width={120} />
          ) : (
            <span className={blink === true && !preventBlinking ? classes.blink : classes.default}>
              {subheader}
            </span>
          )
        }
        avatar={icon}
        titleTypographyProps={{ variant: 'h5' }}
        action={renderAnnotations(annotations)}
      />
      <CardContent>{isLoading ? <DummyText /> : content ?? null}</CardContent>
      {!!actions ? (
        <CardActions className={classes.actions}>{renderActions(actions)}</CardActions>
      ) : null}
    </Card>
  );
};

export { Summary };
