import gql from 'graphql-tag';

export const GetGiftCard = gql`
  query GiftCard($cardNumber: String!, $isoCountryCode: String!) {
    giftCardByCardNumber(cardNumber: $cardNumber, isoCountryCode: $isoCountryCode) {
      id: cardNumber
      alias
      cardNumber
      currencyCode
      currentBalance
      expiryMonth
      expiryYear
      transactions {
        amount
        currencyCode
        info
        transactionTime
      }
    }
  }
`;
