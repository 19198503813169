import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  oktaSignInButton: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    borderRadius: '100px',
    height: '40px',
    fontSize: '14px',
  },
  oktaSignInButtonContent: {
    display: 'flex',
  },
}));
