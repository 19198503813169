import gql from 'graphql-tag';

import { RutrEventFragment } from './fragments';

export const GetRutrTimeline = gql`
  query rollUpTheRimTimelineByTHLegacyCognitoId($cognitoId: String!, $eventId: String) {
    rollUpTheRimTimelineByTHLegacyCognitoId(cognitoId: $cognitoId, eventId: $eventId) {
      count
      events {
        ...RutrEventFragment
      }
    }
  }
  ${RutrEventFragment}
`;
