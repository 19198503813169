import gql from 'graphql-tag';

import {
  LoyaltyCardFragment,
  LoyaltyCustomerFragment,
  LoyaltyTransactionFragment,
} from './fragments';

export const GetLoyaltyByCustomerId = gql`
  query loyaltyByCustomerId(
    $customerId: String!
    $lastTransactionBarcode: String
    $lastTransactionId: String
    $lastTransactionTimestamp: String
    $pageSize: Int
    $startDate: String
    $endDate: String
  ) {
    loyaltyByCustomerId(customerId: $customerId) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard(
        lastTransactionBarcode: $lastTransactionBarcode
        lastTransactionId: $lastTransactionId
        lastTransactionTimestamp: $lastTransactionTimestamp
        pageSize: $pageSize
        startDate: $startDate
        endDate: $endDate
      ) {
        ...LoyaltyCardFragment
        loyaltyTransactions {
          count
          transactions {
            ...LoyaltyTransactionFragment
          }
        }
      }
      rollUpTheRimTimeline {
        count
        events {
          type
        }
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
  ${LoyaltyTransactionFragment}
`;

export const GetCustomerRemediationReasons = gql`
  query GetCustomerRemediationReasons($customerId: String!) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      isoCountryCode
      remediationReasons {
        label
        value
      }
    }
  }
`;

export const GetLoyaltyCustomerByCustomerId = gql`
  query loyaltyByCustomerId($customerId: String!) {
    loyaltyByCustomerId(customerId: $customerId) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard {
        ...LoyaltyCardFragment
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
`;

export const GetLoyaltyCardTransaction = gql`
  query loyaltyTransaction($thLegacyCognitoId: String!, $shortId: String!) {
    loyaltyTransaction(thLegacyCognitoId: $thLegacyCognitoId, shortId: $shortId) {
      ...LoyaltyTransactionFragment
    }
  }
  ${LoyaltyTransactionFragment}
`;
