import { Person, Stars, LocalMall, Store, Redeem } from '@material-ui/icons';
import { ListItem } from 'design-system';

export const SearchInstructions = ({ showRewardSuggestion }: { showRewardSuggestion: boolean }) => {
  return (
    <>
      <ListItem iconTooltipText="Customer" icon={<Person />} text="Customer Email or Cognito ID" />
      {showRewardSuggestion && (
        <ListItem iconTooltipText="Customer" icon={<Stars />} text="Reward Card ID or Barcode" />
      )}
      <ListItem iconTooltipText="Order" icon={<LocalMall />} text="RBI Order ID" />
      <ListItem
        iconTooltipText="Store"
        icon={<Store />}
        text="Store Number, Sanity ID or Address"
      />
      <ListItem iconTooltipText="Gift Card" icon={<Redeem />} text="Card Number" />
    </>
  );
};
