import gql from 'graphql-tag';

export const RutrEventFragment = gql`
  fragment RutrEventFragment on RollUpTheRimEvent {
    createdAt
    eventId
    prizeId
    redemptionCode
    timelineId
    type
    rollMetadata {
      source
    }
  }
`;
