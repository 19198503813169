import { navigate } from '@reach/router';

import { ServiceMode, DriveThruLaneType } from 'types';

import {
  SHOULD_REDIRECT_TO_PREVIEW,
  REDIRECTED_FROM_STAGING,
  SHOULD_LOGIN_WITH_OKTA,
} from '../components/okta-sign-in-button/constants';
import { ICredentials } from '../types/utils';

enum CountryAbbreviation {
  US = 'US',
  CAN = 'CAN',
  UNKNOWN = 'UNKNOWN',
}

enum BarcodePrefix {
  US = '0464',
  US1 = '0474',
  CAN = '0463',
  CAN1 = '0473',
}

export function countryAbbreviationForBarcode(barcode: string | undefined) {
  if (!barcode) {
    return CountryAbbreviation.UNKNOWN;
  }
  const prefix = barcode.slice(0, 4);
  switch (prefix) {
    case BarcodePrefix.US:
    case BarcodePrefix.US1:
      return CountryAbbreviation.US;
    case BarcodePrefix.CAN:
    case BarcodePrefix.CAN1:
      return CountryAbbreviation.CAN;
    default:
      return CountryAbbreviation.UNKNOWN;
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: DriveThruLaneType) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

export function centsToDollars(price: number = 0) {
  return price / 100;
}

export function centsToDollarString(cents: number = 0) {
  return centsToDollars(cents).toFixed(2);
}

export function dateDifference(startDate: Date, endDate: Date) {
  return Math.abs(startDate.getTime() - endDate.getTime()) / (24 * 60 * 60 * 1000);
}

export const getCredentials = (): ICredentials[] => {
  if (typeof localStorage !== 'undefined') {
    const keyList: Array<string> = Object.keys(localStorage).reduce(
      (acc: Array<string>, item: string) => {
        if (item.includes('CognitoIdentityServiceProvider')) {
          return [...acc, item];
        }

        return acc;
      },
      [],
    );
    return keyList.map(
      (item: string): ICredentials => ({
        key: item,
        value: localStorage.getItem(item),
      }),
    );
  } else {
    return [];
  }
};

export const composeEndUrl = (env: string): string => {
  const credentials: ICredentials[] = getCredentials();
  return `${env}?${credentials
    .map(({ key, value }) => `${key}=${value}`)
    .join('&')}&${REDIRECTED_FROM_STAGING}=true`;
};

export const redirectToEnvironmentWithCredentials = (env: string): void => {
  localStorage.removeItem(SHOULD_REDIRECT_TO_PREVIEW);
  localStorage.removeItem(SHOULD_LOGIN_WITH_OKTA);
  navigate(composeEndUrl(env), { replace: true });
};
