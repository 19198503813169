import { useCallback, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import uuidValidate from 'uuid-validate';

import brandConfigs from 'config/brand-resolution';
import {
  lookupCustomerByEmail,
  lookupCustomerByCustomerId,
  lookupGiftCardByCardNumber,
  lookupOrderById,
  searchCardId,
  searchStores,
} from 'remote/queries';
import { EMAIL_REGEX } from 'utils';

import { SearchResult } from './types';

export { searchResultDisplayMap } from './search-result-display-map';
export type { SearchResult };

export const useUniversalSearch = (
  searchTerm: string,
): {
  loading: boolean;
  hits: SearchResult[];
} => {
  const { data: giftCardByCardNumberData, loading: giftCardByCardNumberLoading } = useQuery(
    lookupGiftCardByCardNumber,
    {
      variables: { cardNumber: searchTerm.replace(/-/g, '') },
      skip: searchTerm.replace(/-/g, '').length !== 16 || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: searchStoresData, loading: searchStoresLoading } = useQuery(searchStores, {
    variables: { searchTerm },
    skip: !searchTerm || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const { data: customerByEmailData, loading: customerByEmailLoading } = useQuery(
    lookupCustomerByEmail,
    {
      variables: { email: searchTerm },
      skip: !EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: customerByCustomerIdData, loading: customerByCustomerIdLoading } = useQuery(
    lookupCustomerByCustomerId,
    {
      variables: { customerId: searchTerm },
      skip: !uuidValidate(searchTerm) || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: orderByIdData, loading: orderByIdLoading } = useQuery(lookupOrderById, {
    variables: { rbiOrderId: searchTerm },
    skip: !uuidValidate(searchTerm) || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const { data: customerByCardId, loading: customerByCardIdLoading } = useQuery(searchCardId, {
    variables: { cardId: searchTerm.replace(/-/g, '') },
    // this is a TH specific feature
    // cardId is always 12 characters long and barcodes are never less than 85 chars
    skip:
      !brandConfigs.thLoyaltyEnabled ||
      (searchTerm.length < 85 && searchTerm.replace(/-/g, '').length !== 12) ||
      EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const loading =
    customerByEmailLoading ||
    customerByCustomerIdLoading ||
    orderByIdLoading ||
    searchStoresLoading ||
    giftCardByCardNumberLoading ||
    customerByCardIdLoading;

  const hits = [
    customerByEmailData?.Customer,
    customerByCustomerIdData?.Customer,
    orderByIdData?.Order,
    orderByIdData?.Order?.customer,
    ...(searchStoresData?.stores ?? []),
    giftCardByCardNumberData?.giftCardByCardNumber,
    customerByCardId?.customerByRewardsCardId,
  ].filter(Boolean);

  return { loading, hits };
};

export const useUniversalSearchFocus = (): {
  focused: boolean;
  setFocused: Function;
} => {
  const [focused, setFocused] = useState(false);
  const handleFocused = useCallback((isFocused) => {
    setFocused(isFocused);
  }, []);

  return { focused, setFocused: handleFocused };
};
