import { useCallback } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import { GetRutrTimeline } from 'remote/queries';

const useRollUpTheRim = () => {
  const [lazilyFetchRutrTimeline, { data = {}, loading, error }] = useLazyQuery(GetRutrTimeline);

  const getRollUpTheRimTimeline = useCallback(
    (cognitoId, eventId) => {
      lazilyFetchRutrTimeline({ variables: { cognitoId, eventId } });
    },
    [lazilyFetchRutrTimeline],
  );

  const { events, count } = data.rollUpTheRimTimelineByTHLegacyCognitoId || {};

  return {
    loading,
    error,
    events,
    count,
    getRollUpTheRimTimeline,
  };
};

export default useRollUpTheRim;
