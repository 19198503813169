import React, { FC } from 'react';

import { Fab } from '@material-ui/core';
import { navigate } from '@reach/router';

import LockIcon from '../common/icons/lock-icon';
import { PREVIEW_URL, SHOULD_LOGIN_WITH_OKTA, STAGING_URL } from './constants';
import { useStyles } from './styles';

const getRedirectUrl = (): string => {
  const previewUrl = new URL(window.location.href).origin;
  return `${STAGING_URL}?${SHOULD_LOGIN_WITH_OKTA}=true&${PREVIEW_URL}=${previewUrl}`;
};

export const OktaSignInButton: FC = () => {
  const classes = useStyles();

  return (
    <Fab
      variant="extended"
      color="primary"
      className={classes.oktaSignInButton}
      size="large"
      data-testid="okta-sign-in-button"
      onClick={() => {
        navigate(getRedirectUrl(), { replace: true });
      }}
    >
      <div className={classes.oktaSignInButtonContent}>
        OKTA SIGN-IN
        <LockIcon />
      </div>
    </Fab>
  );
};
