import gql from 'graphql-tag';

import { CartEntryFragment } from './fragments';

export const FetchOrder = gql`
  query Order($rbiOrderId: String!) {
    Order(rbiOrderId: $rbiOrderId) {
      id: rbiOrderId
      __typename
      cart {
        cartEntries {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                  children {
                    ...CartEntryFragment
                  }
                }
              }
            }
          }
        }
        discounts {
          name
          value
        }
        donations {
          id
          name
          totalCents
        }
        feCountryCode
        payment {
          cardType
          ccLast4
          fdCorrelationId
        }
        platform
        serviceMode
        storeAddress {
          addressLine1
          addressLine2
          city
          country
          state
        }
        storeDetails {
          timezone
        }
        storeId
        subTotalCents
        taxCents
        totalCents
        userId #  currently required for logging refund events to mParticle
      }
      catering {
        declineReason
        pickupTime
        notes
        numberOfGuests
        includeUtensils
        organizerName
      }
      createdAt
      customer {
        id: customerId
        email
        customerId
        name
        fdCustomerId
        isoCountryCode
      }
      delivery {
        dropoff {
          beforeTime
        }
        feeCents
        feeDiscountCents
        status
        tipCents
        cancellation {
          reason
          category
        }
      }
      loyaltyTransaction {
        pointsEarned
        pointsBalance
        pointsUsed
        bonusPointsEarned
        rewardsUsed
      }
      order {
        refund {
          adminUserEmail
          approvedAmount
          primaryReason
          refundedItems {
            name
          }
          secondaryReason
          subtotal
          tax
          transactionDateTime
        }
      }
      remediations {
        amount
        created
        displayName
        remediationReason
        remediationType
        remediationComments
        supportEmail
        transactionId
        transactionType
        userId
      }
      paymentStatus
      paymentVersion
      rbiOrderId
      status
      userId
    }
  }
  ${CartEntryFragment}
`;
