import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  oktaLogo: {
    width: theme.spacing(6),
    paddingLeft: theme.spacing(1),
  },
  oktaSignInButton: {
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    borderRadius: '100px',
    height: '40px',
  },
  oktaSignInButtonContent: {
    display: 'flex',
  },
}));
