import { TableRow, TableCell } from '@material-ui/core';

import CopyableText from 'components/copyable-text';
import DummyText from 'components/dummy-text';
import { EMPTYDATA } from 'components/utils/constants';

import { oneColumnTableProps } from './types';

const OneColumnTableRow = ({
  loading = false,
  empty = false,
  value,
  fallback = <>{EMPTYDATA}</>,
  copyable = false,
  multiline = false,
  className = '',
}: oneColumnTableProps) => {
  return (
    <TableRow data-testid="one-column-table-row">
      <TableCell
        data-testid="one-column-table-cell"
        style={{
          overflow: multiline ? 'unset' : 'hidden',
          whiteSpace: multiline ? 'pre-line' : 'nowrap',
          textOverflow: multiline ? 'unset' : 'ellipsis',
        }}
        className={className}
      >
        {loading ? (
          <DummyText />
        ) : copyable ? (
          <CopyableText annotation="" text={value} />
        ) : empty ? (
          fallback
        ) : (
          value
        )}
      </TableCell>
    </TableRow>
  );
};

export default OneColumnTableRow;
