import gql from 'graphql-tag';

export const ReplaceCard = gql`
  mutation giftCardsReplace($input: GiftCardsReplaceInput!) {
    giftCardsReplace(input: $input) {
      giftCard {
        balance
        cardNumber
      }
    }
  }
`;
