import { Dispatch, SetStateAction } from 'react';

import { AwsCognitoOAuthOpts } from '@aws-amplify/auth/lib-esm/types';
import { Button, Link } from '@material-ui/core';

import Loader from 'components/loader';
import { oktaAuthOptions } from 'remote/auth/cognito';

import { useStyles } from './styles';

export const getOktaRedirectUrl = (
  setError?: React.Dispatch<React.SetStateAction<string>>,
): string => {
  if (!oktaAuthOptions?.oauth) {
    if (setError) {
      setError('Error connecting to Okta');
    }
    return '';
  }
  const oauth = oktaAuthOptions.oauth as AwsCognitoOAuthOpts;
  const oktaRedirectUrl = new URL(`https://${oauth.domain}/oauth2/authorize/`);

  const oktaRedirectUrlParams = {
    identity_provider: 'Okta',
    redirect_uri: oauth.redirectSignIn,
    client_id: oktaAuthOptions.userPoolWebClientId,
    response_type: oauth.responseType,
    scope: encodeURIComponent(oauth.scope.join(' ')),
  };

  Object.keys(oktaRedirectUrlParams).forEach((param) =>
    oktaRedirectUrl.searchParams.set(param, oktaRedirectUrlParams[param]),
  );

  return decodeURI(oktaRedirectUrl.toString());
};

const OktaLogin = ({
  setError,
  loading,
}: {
  setError: Dispatch<SetStateAction<string>>;
  loading: boolean;
}) => {
  const classes = useStyles();

  return loading ? (
    <Loader />
  ) : (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        href={getOktaRedirectUrl(setError)}
        className={classes.submit}
        size="large"
      >
        <span>
          Sign in via
          <img
            src="https://www.okta.com/sites/default/files/Okta_Logo_White_Medium-thumbnail.png"
            className={classes.oktaLogo}
            alt="Okta Logo"
          />
        </span>
      </Button>
      <Link
        href="https://rbi.service-now.com/rbi?id=sc_cat_item&sys_id=073ea41e1b9d2d108ba07445cc4bcbaa"
        target="_blank"
      >
        Request OKTA Access
      </Link>
    </>
  );
};

export { OktaLogin };
