import gql from 'graphql-tag';

export const updateEmailMutation = gql`
  mutation updateEmail(
    $customerId: String!
    $email: String!
    $region: String!
    $previousEmail: String!
    $isFSUser: Boolean!
  ) {
    updateEmail(
      customerId: $customerId
      email: $email
      region: $region
      previousEmail: $previousEmail
      isFSUser: $isFSUser
    ) {
      customerId
      email
    }
  }
`;
