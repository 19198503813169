import {
  ListAlt,
  LocalMall,
  LocalOffer,
  People,
  Redeem,
  VideogameAsset,
  Stars,
  Store,
  AccountBalanceWallet,
} from '@material-ui/icons';

import THLogo from 'components/logos/th';
import { TIM_HORTONS_DARK_RED } from 'state/theme/colors';

import { Permission } from '../../services/graphql/src/lib/users/roles-and-permissions';
import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

const thConfigs: IBrandConfigs = {
  links: [
    {
      name: 'Customers',
      route: routes.customer,
      Icon: People,
    },
    {
      name: 'Orders',
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: 'Support History',
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: 'Gift Cards',
      route: routes.giftCard,
      Icon: Redeem,
    },
    {
      name: 'Offers',
      route: routes.offersTH,
      Icon: LocalOffer,
    },
    {
      name: 'Loyalty',
      route: routes.thLoyalty,
      Icon: Stars,
    },
    {
      name: 'FS',
      route: routes.fsTransactions,
      Icon: AccountBalanceWallet,
      accessPermission: Permission.FSTransactionsView,
    },
    {
      name: 'Hockey Challenges',
      route: routes.hockeyChallenges,
      Icon: VideogameAsset,
    },
    {
      name: 'Stores',
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: THLogo,
  theme: {
    brandColor: TIM_HORTONS_DARK_RED,
  },
  thLoyaltyEnabled: true,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 10,
      max: 2000,
      step: 1,
    },
    CAN: {
      min: 10,
      max: 2000,
      step: 1,
    },
    DEFAULT: {
      min: 10,
      max: 250,
      step: 1,
    },
  },
  loyaltyPointsWithdrawRange: {
    USA: {
      min: 1,
      max: 5000,
    },
    CAN: {
      min: 1,
      max: 5000,
    },
  },
  loyaltyRollsRange: {
    USA: {
      min: 1,
      max: 12,
      additionalValues: [50],
    },
    CAN: {
      min: 1,
      max: 12,
      additionalValues: [50],
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 200,
    CAN: 200,
    DEFAULT: 50,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
};

export default thConfigs;
