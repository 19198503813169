import gql from 'graphql-tag';

export const LoyaltyTransactionFragment = gql`
  fragment LoyaltyTransactionFragment on LoyaltyTransaction {
    address1
    address2
    appliedOffers
    basketTaxes
    basketTotal
    bonusPoints
    city
    discountAmount
    expiredPoints {
      barcode
      expirationDate
      points
      ttl
    }
    isCustomerServiceVisit
    isVisit
    items {
      name
      plu
      uniqueId
      basePrice
      totalPrice
    }
    offerId
    partnerId
    pointsEarned
    pointsUsed
    postalCode
    posType
    rewardEarned
    rewardRedeemed
    rollsEarned
    scanAndPay
    stateOrProvince
    storeId
    tag
    timestamp
    timezone
    transactionId
    visitType
    wasDiscountActive
  }
`;
