import gql from 'graphql-tag';

export const IssueLoyaltyRolls = gql`
  mutation issueLoyaltyRolls($cardId: String!, $customerId: String!, $rolls: Int!) {
    issueLoyaltyRolls(cardId: $cardId, customerId: $customerId, rolls: $rolls) {
      succeeded
      rolls
    }
  }
`;
