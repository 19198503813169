import { Brand } from 'types';

import { BRAND } from '../constants';
import bkConfigs from './bk-configs';
import { IBrandConfigs } from './brand-config';
import fhsConfigs from './fhs-configs';
import plkConfigs from './plk-configs';
import thConfigs from './th-configs';

/*
 * The idea behind this file is to keep all brand driven logic
 * together in one place so that we're not constantly pulling in
 * Brand and BRAND from utils/constants in order to conditionally
 * render different areas of the app
 */

const brandConfigs: IBrandConfigs = {
  [Brand.BK]: bkConfigs,
  [Brand.PLK]: plkConfigs,
  [Brand.TH]: thConfigs,
  [Brand.FHS]: fhsConfigs,
}[BRAND];

export default brandConfigs;
